import { Injectable } from "@angular/core";
import { StayInLoop } from "@api/models";
import { ApiService } from "@api/services";
import { Action, State, StateContext } from "@ngxs/store";

export class SubmitStayInLoopAction {
  static readonly type = "[Marketing] Submit Stay In Loop";
  constructor(public request: StayInLoop) {}
}

@State<object>({
  name: "StayInLoop",
  defaults: {},
})
@Injectable()
export class StayInLoopState {
  constructor(private api: ApiService) {}

  @Action(SubmitStayInLoopAction)
  submitStayInLoop(_ctx: StateContext<object>, action: SubmitStayInLoopAction) {
    this.api
      .stayInLoopPost({
        body: {
          email: action.request.email,
        },
      })
      .subscribe();
  }
}
